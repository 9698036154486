import PropTypes from 'prop-types';
import { gql, useLazyQuery } from '@apollo/client';
import { Button, ButtonGroup, Dropdown, MenuItem } from 'react-bootstrap';
import { useRef, useEffect, useState, useMemo } from 'react';
import { Icon } from '@iconify-icon/react';
import { DAM_INTEGRATIONS_WITH_SETUP } from '@/storychief/constants/Constants';
import DAMMenuItems from '@/media/components/MediaPicker/DAMMenuItems';
import { useLoadFiles } from '@/storychief/hooks';
import useModal from '@/modals/hooks/useModal';
import { fileTypesImages, fileTypesVideos } from '@/media/constants/Constants';
import PostsetAttachmentTypeOverlayTrigger from '@/postsets/components/PostsetAttachmentTypeOverlayTrigger';
import StoryChief from '@/storychief/index';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element]),
  display: PropTypes.oneOf(['split-button', 'dropdown', 'button']),
  className: PropTypes.string,
  bsSize: PropTypes.oneOf(['lg', 'sm', 'xs']),
  bsStyle: PropTypes.oneOf(['default', 'primary']),
  onLoadCompleted: PropTypes.func.isRequired,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
  disabled: PropTypes.bool,
  multiSelect: PropTypes.bool,
  includeBrowser: PropTypes.bool,
  hideUnsplash: PropTypes.bool,
  crop: PropTypes.bool,
  convertPngToJpeg: PropTypes.bool,
  allowedFileTypes: PropTypes.arrayOf(
    PropTypes.shape({
      mime: PropTypes.string.isRequired,
      extension: PropTypes.string.isRequired,
    }),
  ),
  icon: PropTypes.element,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  allowedFileTypesErrorMessage: PropTypes.func,
};

const defaultProps = {
  children: null,
  display: 'split-button',
  className: 'btn-outline',
  bsSize: null,
  bsStyle: 'default',
  minWidth: null,
  minHeight: null,
  disabled: false,
  crop: false,
  includeBrowser: true,
  convertPngToJpeg: true,
  multiSelect: false,
  icon: null,
  title: null,
  allowedFileTypes: [...fileTypesImages, ...fileTypesVideos],
  isLoading: false,
  hideUnsplash: false,
  allowedFileTypesErrorMessage: undefined,
};

const DAM_INTEGRATIONS_WITH_SETUP_QUERY = gql`
  query DAMIntegrationsWithSetup($filter: IntegrationFilters) {
    integrations(filter: $filter) {
      __typename
      id
      display_name
      integration_type
      info {
        cloud_name
        api_key
        environment
      }
    }
  }
`;

function MediaPicker({
  children,
  bsSize,
  onLoadCompleted,
  disabled,
  allowedFileTypes,
  minWidth,
  minHeight,
  crop,
  includeBrowser,
  convertPngToJpeg,
  multiSelect,
  display,
  icon,
  title,
  className,
  bsStyle,
  isLoading,
  hideUnsplash,
  allowedFileTypesErrorMessage,
  droppedItems,
}) {
  // Queries
  const [
    loadDamIntegrationsWithSetup,
    { data: { integrations: damIntegrationsWithSetup = [] } = {}, loading: loadingIntegrations },
  ] = useLazyQuery(DAM_INTEGRATIONS_WITH_SETUP_QUERY, {
    variables: {
      filter: {
        integration_type: [...DAM_INTEGRATIONS_WITH_SETUP],
      },
    },
  });

  // State
  const [preLoading, setPreLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Hooks
  const imageUrlUploadModal = useModal('ImageUrlUpload');
  const mediaLibraryModal = useModal('MediaLibraryModal');
  const fileInput = useRef();
  const [load, { loadingLoadFiles }] = useLoadFiles({
    allowedFileTypes,
    minWidth,
    minHeight,
    crop,
    convertPngToJpeg,
    onCompleted: (files) => {
      onLoadCompleted(files);
    },
    allowedFileTypesErrorMessage,
  });

  // Variables
  const isDisabled = disabled || preLoading || loadingLoadFiles || isLoading;
  const isDocument = allowedFileTypes.some((t) => t.mime.startsWith('application'));

  // Functions
  function onUploadFromComputerClick() {
    fileInput.current.value = null;
    fileInput.current.click();
  }

  function toggleModalImageUrlUpload() {
    imageUrlUploadModal.toggle({
      props: {
        uploadImage: (_image) => load([_image]),
      },
    });
  }

  function toggleModalImageBrowser() {
    mediaLibraryModal.toggle({
      props: {
        multiSelect,
        allowedFileTypes,
        allowedFileTypesErrorMessage,
        onInsert: (_images) => load(_images),
      },
    });
  }

  function getMenuItems() {
    return (
      <>
        {display === 'dropdown' && (
          <>
            <MenuItem disabled={isDisabled} onSelect={onUploadFromComputerClick}>
              <div className="d-flex flex-align-items-center flex-row">
                <Icon icon="fa:upload" inline width="16" height="14" />
                <div className="gutter-left-2">Upload from computer</div>
              </div>
            </MenuItem>
            {/* Not supported for documents & freemium */}
            {includeBrowser && !isDocument && !StoryChief.account.freemium && (
              <MenuItem disabled={isDisabled} onSelect={toggleModalImageBrowser}>
                <div className="d-flex flex-align-items-center flex-row">
                  <Icon icon="mdi:grid" inline width="16" height="14" />
                  <div className="gutter-left-2">Media library</div>
                </div>
              </MenuItem>
            )}
          </>
        )}
        {!!allowedFileTypes.find((_type) => _type.mime.startsWith('image')) && (
          <MenuItem disabled={isDisabled} onSelect={toggleModalImageUrlUpload}>
            <div className="d-flex flex-align-items-center flex-row">
              <Icon icon="fa:link" inline width="16" height="14" />
              <div className="gutter-left-2">Upload image from URL</div>
            </div>
          </MenuItem>
        )}

        {/* Not supported for documents */}
        {!isDocument && !StoryChief.account.freemium && (
          <>
            <MenuItem divider />
            <DAMMenuItems
              damIntegrationsWithSetup={damIntegrationsWithSetup}
              disabled={isDisabled}
              loading={loadingIntegrations}
              onFileLoaded={(files) => {
                setPreLoading(false);
                load(files);
              }}
              onFileLoading={() => {
                setDropdownOpen(false);
                setPreLoading(true);
              }}
              onFileLoadError={() => {
                // eslint-disable-next-line no-alert
                window.alert("We couldn't fetch your file, please try again.");
                setPreLoading(false);
              }}
              multiSelect={multiSelect}
              allowedFileTypes={allowedFileTypes}
              hideUnsplash={hideUnsplash}
            />
          </>
        )}
      </>
    );
  }

  function getTitle(showTitle = true) {
    return (
      <span>
        {loadingLoadFiles || preLoading || isLoading ? (
          <span className="d-inline-block gutter-right-1">
            <span className="animated-spin d-inline-block">
              <span className="icon-spin1" />
            </span>
          </span>
        ) : (
          icon || (
            <span className="d-inline-block gutter-right-1">
              <Icon icon="fa:upload" inline />
            </span>
          )
        )}
        {showTitle && (title || 'Upload')}
      </span>
    );
  }

  function handleDropdownToggle(isOpen) {
    if (isOpen) {
      loadDamIntegrationsWithSetup().then(() => null);
    }

    setDropdownOpen((prevState) => !prevState);
  }

  useEffect(() => {
    if (droppedItems && droppedItems.length > 0) {
      load(droppedItems);
    }
  }, [droppedItems]);

  return useMemo(
    () => (
      <>
        {(() => {
          switch (display) {
            case 'button':
              return (
                <Button
                  className={className}
                  onClick={toggleModalImageBrowser}
                  bsStyle={bsStyle}
                  bsSize={bsSize}
                  disabled={isDisabled}
                >
                  {children}
                  {isLoading && (
                    <span className="gutter-left-1">
                      <span className="icon-spin1 animated-spin d-inline-block" />
                    </span>
                  )}
                </Button>
              );
            case 'split-button':
              return (
                <ButtonGroup className="image-picker">
                  <Button
                    className={className}
                    onClick={onUploadFromComputerClick}
                    bsStyle={bsStyle}
                    bsSize={bsSize}
                    disabled={isDisabled}
                  >
                    {getTitle()}
                  </Button>
                  {includeBrowser && !StoryChief.account.freemium && (
                    <Button
                      className={className}
                      onClick={toggleModalImageBrowser}
                      bsStyle={bsStyle}
                      bsSize={bsSize}
                      disabled={isDisabled}
                    >
                      Media library
                    </Button>
                  )}
                  <Dropdown
                    id="image-picker"
                    pullRight
                    bsStyle={bsStyle}
                    bsSize={bsSize}
                    disabled={isDisabled}
                    open={dropdownOpen}
                    onToggle={handleDropdownToggle}
                  >
                    <Dropdown.Toggle className={className} />
                    <Dropdown.Menu>{getMenuItems()}</Dropdown.Menu>
                  </Dropdown>
                </ButtonGroup>
              );
            case 'dropdown':
              return (
                <PostsetAttachmentTypeOverlayTrigger attachmentType={title}>
                  <Dropdown
                    id="image-picker"
                    className="image-picker"
                    bsStyle={bsStyle}
                    bsSize={bsSize}
                    disabled={isDisabled}
                    open={dropdownOpen}
                    onToggle={handleDropdownToggle}
                  >
                    <Dropdown.Toggle className={className}>{getTitle(false)}</Dropdown.Toggle>
                    <Dropdown.Menu>{getMenuItems()}</Dropdown.Menu>
                  </Dropdown>
                </PostsetAttachmentTypeOverlayTrigger>
              );
            default:
              return null;
          }
        })()}
        <input
          type="file"
          ref={fileInput}
          onChange={(e) => load(e.target.files)}
          disabled={isDisabled}
          multiple={multiSelect}
          accept={allowedFileTypes.map((type) => type.extension).join(', ')}
          style={{ display: 'none' }}
        />
      </>
    ),
    [isDisabled, loadingIntegrations, dropdownOpen, className, onLoadCompleted],
  );
}

MediaPicker.propTypes = propTypes;
MediaPicker.defaultProps = defaultProps;

export default MediaPicker;
